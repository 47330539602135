/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/normalize.css@8.0.1/normalize.min.css
 * - /npm/bootstrap@4.6.0/dist/css/bootstrap.min.css
 * - /npm/bootstrap-table@1.19.1/dist/bootstrap-table.min.css
 * - /npm/bootstrap-table@1.19.1/dist/extensions/filter-control/bootstrap-table-filter-control.min.css
 * - /npm/@coreui/coreui@3.4.0/dist/css/coreui.min.css
 * - /npm/leaflet@1.7.1/dist/leaflet.min.css
 * - /npm/izitoast@1.4.0/dist/css/iziToast.min.css
 * - /npm/tippy.js@6.3.1/animations/scale.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 * - /npm/daterangepicker@3.1.0/daterangepicker.min.css
 * - /npm/slim-select@1.27.0/dist/slimselect.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
